<template>
  <b-container class="whole-container">
    <h2>Documentation du Tamat</h2>
    <b-container class="view">
      <sl-vue-tree v-if="dataLoaded" v-model="slTree" class="tree">
        <template slot="toggle" slot-scope="{ node }">
          <span class="item-icon">
            <b-icon icon="folder2" v-if="!node.isLeaf && !node.isExpanded"></b-icon>
            <b-icon icon="folder2-open" v-if="!node.isLeaf && node.isExpanded"></b-icon>
          </span>
        </template>
        <template slot="title" slot-scope='{ node }'>
          <span class="item-icon" v-if="node.isLeaf">
            <b-icon icon="file-earmark"></b-icon>
          </span>
          <div v-if="node.isLeaf" class="path leaf" :title="node.title" v-on:click="openPDF(node.title, node.data.parent)">
            {{ node.title | truncate(25, '...') }}
          </div>
          <div v-else class="path" :title="node.title">
            {{ node.title | truncate(25, '...') }}
          </div>
        </template>
      </sl-vue-tree>
      <b-container class="search">
        <b-form @submit.prevent="search">
          <b-row class="my-3">
            <b-col sm="4">
              <label for="doc-query" label-align-sm="left" label-cols-sm="3">Rechercher dans la documentation du Tamat</label>
            </b-col>
            <b-col>
              <b-input-group>
              <b-form-input id="doc-query" type="text"  placeholder="déclencheur..." v-model="query"/>
                <b-input-group-append>
                <b-button type="submit">Rechercher</b-button>
                </b-input-group-append>
             </b-input-group>
            </b-col>
          </b-row>
          <b-row align-h="center" class="my-3">
            <b-spinner v-if="requesting"/>
          </b-row>
          <b-card v-if="response" title="Résultats">
            <b-card-text>
              <b-row v-for="document in response" :key="`result-${response.indexOf(document)}`" class='document_row'>
                <div class="document">
                  <span class="filename" @click="openPDF(document.filename, document.parent)">{{ document.filename }}</span>
                  <span class="occurences">{{ document.nb_occ }} occurence(s) trouvée(s)</span>
                </div>
                <div v-for="(page, index) in document.pages" :key="`doc-${response.indexOf(document)}-${index}`" class="page">
                  <div class="doc_page_info">
                    <div class="doc_page" @click="openPDFPage(document.filename, page, document.parent)">Page {{page[0]}}</div>
                    <div class="page_occ">{{page[1].nb_occ}} / {{document.nb_occ}}</div>
                  </div>
                  <div v-for="(items, index) in page[1].results" :key="`doc-${response.indexOf(document)}-${index}`" class="bookmark">
                    <div v-for="(results, bookmark) in items" :key="`doc-${response.indexOf(document)}-${bookmark}`">
                      {{bookmark}}
                      <div
                        v-for="context in results"
                        :key="`context-${response.indexOf(document)}-${bookmark}-${context}`"
                        v-html="context"
                        class="context">
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </b-form>
      </b-container>
    </b-container>
  </b-container>
</template>

<script>
import axios from 'axios';
import SlVueTree from 'sl-vue-tree';
import {BIcon} from 'bootstrap-vue';
export default {
  name: 'Documentation',
  components: {
    SlVueTree,
    BIcon
  },
  data: function() {
    return {
      query: "",
      response: "",
      requesting: false,

      arbre: {},
      dataLoaded: false,

      Marginx: 30,
      Marginy: 30,
      radius: 3,
      leafTextMargin: 10,
      nodeTextMargin: 10,
      treeDisplayData: {},
      slTree: {},
      baseUrl : process.env.BASE_URL
    }
  },
  methods: {
    search() {
      console.log(this.chips);
      this.requesting = true;
      this.response = "";
      axios.get(`https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/search/?query=${encodeURIComponent(this.query)}`).then(
        (response) => {
          console.log(response.data.res);
          if(response.data.res) {
            this.response = response.data.res;
          } else {
            this.response = "";
          }
          this.requesting = false;
        }
      ).catch(
        () => {
          this.response = "";
          this.requesting = false;
        }
      );
    },
    openPDFPage(filename, page, parent) {
      if(parent){window.open(`https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/load_doc/${encodeURIComponent(parent)}/${encodeURIComponent(filename)}#page=${page}`, '_blank').focus();}
      else{window.open(`https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/load_doc/${encodeURIComponent(filename)}#page=${page}`, '_blank');} 
    },
    openPDF(filename, parent) {
      if(parent){window.open(`https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/load_doc/${encodeURIComponent(parent)}/${encodeURIComponent(filename)}`, '_blank');}
      else{window.open(`https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/load_doc/${encodeURIComponent(filename)}`, '_blank');} 
    },
    renderContext(context) {
      let regexp = new RegExp(this.query, "i");
      return context.replace(regexp, '<span class="matches">$&</span>');
    },
    loadTree() {
      axios.get("https://www.master-7rqtwti-ldcdbigcxai5y.ovhcloud-fr-1.webpaas.ovh.net/server/load_tree/").then(
        (response) => {
          this.slTree = [response.data];
          this.dataLoaded = true;
        }
      )
    },
    onClick(e) {
      if(e.data.name.endsWith(".pdf")) {
        this.openPDF(e.data.name);
      }
    },
  },
  mounted() {
    this.loadTree();
  }
}
</script>

<style>
.sl-vue-tree-nodes-list{
  margin-left: 5%;
}
span.sl-vue-tree-toggle{
  cursor: pointer;
}
.sl-vue-tree-title{
  display: flex;
  flex-flow: row nowrap;
}
.sl-vue-tree-title > .path{
  margin-left: 10px;
}
.path.leaf{
  cursor: pointer;
  line-height: 1.65;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.tree > div{
  padding: 10px;
}
span.matches{
  background-color: #ff9;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whole-container{
  max-width: 98%;
  margin: 20px 1%;
}
.view.container{
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
}
.tree{
  flex-grow: 0;
  width: 22%;
  font-size: 0.85em;
  text-align: left;
  background-color: #f1f1f1;
  border: 1px solid black;
  border-radius: 5px;
}
.document_row{
  margin-bottom: 1.75rem;
}
.document{
  text-align: left;
}
.filename, .doc_page{
  text-decoration: underline;
  cursor: pointer;
}
.doc_page, .page_occ{
  display: inline-block;
}
.filename{
  color: #09759B;
}
.doc_page{
  color: #06749a;
}
.occurences, .bookmark, .context, .page_occ{
  margin-left: 1.5em;
}
.page_occ{
  font-style: italic;
}
.page{
  text-align: left;
  margin-left: 2em;
  margin-top: 0.5rem;
}
.bookmark{
  font-size: 0.9rem;
}
.context{
  font-size: 0.85rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.context:before{
  content:open-quote;
}
.context:after{
  content:close-quote;
}
</style>
